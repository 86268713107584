import PropTypes from "prop-types";
import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import { useForm, useWatch } from "react-hook-form";
import styled, { css } from "styled-components";

import { validateTrim } from "_helpers";
import { getStatesOptions } from "_helpers/variables";

import PhoneField from "components/atomic/molecules/fields/PhoneField";
import SelectField from "components/atomic/molecules/fields/SelectField";
import SwitchField from "components/atomic/molecules/fields/SwitchField";
import TextField from "components/atomic/molecules/fields/TextField";

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  /* min-height: 500px; */
  padding: 8px;
  box-shadow: 0 0 3px lightgray inset;
  border-radius: 8px;

  ${({ theme }) => css`
    background-color: ${theme.colors["cl-content-bg"]};
  `}
`;

const FieldsGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

// const FieldsGroupHeader = styled.div`
//   text-align: center;
//   margin: 0 0 24px 0;
// `;

const FieldsGroupContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;

  & > * {
    flex: 1;
  }

  ${({ $flex }) =>
    $flex &&
    css`
      flex: ${$flex};
    `}

  @media (max-width: 991px) {
    flex-direction: column;
  }
`;

const Col = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;

  ${({ smallGap }) =>
    smallGap &&
    css`
      gap: 8px;
    `}

  ${({ $flex }) =>
    $flex &&
    css`
      flex: ${$flex};
    `}
`;

// const ButtonsGroup = styled.div`
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   gap: 24px;
// `;

const rules = {
  firstName: () => ({
    required: "First Name is a required field",
    maxLength: {
      value: 100,
      message: "First Name should be less or equal 100 symbols",
    },
    pattern: {
      value: /^[ a-zA-Z\\&-]{1,100}$/i,
      message:
        "There shouldn't be numbers here, the only punctuation and special characters allowed are hyphen (-), backslash (\\) and ampersand (&)",
    },
    validate: validateTrim,
  }),
  lastName: () => ({
    required: "Last Name is a required field",
    maxLength: {
      value: 30,
      message: "Last Name should be less or equal 30 symbols",
    },
    pattern: {
      value: /^[ a-zA-Z\\&-]{1,30}$/i,
      message:
        "There shouldn't be numbers here, the only punctuation and special characters allowed are hyphen (-), backslash (\\) and ampersand (&)",
    },
    validate: validateTrim,
  }),
  phone: () => ({
    required: "Phone is a required field",
    validate: (value) => {
      if (value?.includes("_")) {
        return "You must provide a valid phone number";
      }

      return true;
    },
  }),
  email: () => ({
    required: "Email is a required field",
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: "Invalid Email address",
    },
    maxLength: {
      value: 100,
      message: "Email should be less or equal 100 symbols",
    },
  }),

  companyName: () => ({
    required: "Entity Name is a required field",
    validate: validateTrim,
  }),

  address1: () => ({
    required: "Address is a required field",
    validate: validateTrim,
  }),

  address2: () => ({
    // required: "Apt/Suite is a required field",
    validate: validateTrim,
  }),

  state: () => ({
    required: "State is a required field",
    validate: validateTrim,
  }),

  city: () => ({
    required: "City is a required field",
    // pattern: {
    //   value: /^[ a-zA-Z\-&]{1,30}$/i,
    //   message:
    //     "There shouldn't be numbers here, the only punctuation and special characters allowed are hyphen (-), backslash (\\) and ampersand (&)",
    // },
    validate: validateTrim,
  }),

  zip: () => ({
    required: "ZIP is a required field",
    maxLength: {
      value: 5,
      message: "ZIP should be equal 5 digits",
    },
    pattern: {
      value: /^\d{5}$/i,
      message: "Provide ZIP in the required format",
    },
    validate: validateTrim,
  }),
};

const initValues = {
  firstName: "",
  lastName: "",
  companyName: "",
  phone: "",
  email: "",
  raAddress: {
    address1: "",
    address2: "",
    state: "",
    city: "",
    zip: "",
    country: "US",
  },
};

// eslint-disable-next-line react/display-name
const AmendmentChangeRegisterAgentForm = forwardRef((props, ref) => {
  const { handlers } = props;
  const { onSubmit } = handlers;

  const formIndividualInfo = useForm({
    defaultValues: {
      isIndividual: true,
    },
  });
  const valuesIndividualInfo = useWatch({ control: formIndividualInfo.control });

  const form = useForm({ defaultValues: initValues, reValidateMode: "onChange" });
  const {
    control,
    setValue,
    formState: { errors },
    handleSubmit: createSubmitHandler,
  } = form;

  const handleSubmit = createSubmitHandler(onSubmit);

  useImperativeHandle(
    ref,
    () => ({
      submit: () => handleSubmit(),
    }),
    [handleSubmit]
  );

  useEffect(() => {
    if (valuesIndividualInfo.isIndividual) {
      setValue("companyName", "");
    } else {
      setValue("firstName", "");
      setValue("lastName", "");
    }
  }, [valuesIndividualInfo.isIndividual]);

  return (
    <>
      <StyledForm ref={ref} onSubmit={handleSubmit}>
        <FieldsGroup>
          <p>New Registered Agent Information</p>
          <FieldsGroupContent>
            <SwitchField
              column
              name="isIndividual"
              labelOn="Individual"
              labelOff="Organization"
              control={formIndividualInfo.control}
              errors={formIndividualInfo.formState.errors}
            />
          </FieldsGroupContent>
          {valuesIndividualInfo?.isIndividual && (
            <FieldsGroupContent>
              <TextField
                column
                name="firstName"
                label="First Name"
                placeholder="First Name..."
                control={control}
                errors={errors}
                rules={rules.firstName()}
              />

              <TextField
                column
                name="lastName"
                label="Last Name"
                placeholder="Last Name..."
                control={control}
                errors={errors}
                rules={rules.lastName()}
              />
            </FieldsGroupContent>
          )}
          {!valuesIndividualInfo?.isIndividual && (
            <FieldsGroupContent>
              <TextField
                column
                name="companyName"
                label="Entity Name"
                placeholder="Entity Name..."
                control={control}
                errors={errors}
                rules={rules.companyName()}
              />
            </FieldsGroupContent>
          )}

          <FieldsGroupContent>
            <Col $flex="9">
              <TextField
                column
                name="raAddress.address1"
                label="Street Address"
                placeholder="Address..."
                control={control}
                errors={errors}
                rules={rules.address1()}
              />
            </Col>
            <Col $flex="3">
              <TextField
                column
                name="raAddress.address2"
                label="Apt or Suite"
                placeholder="Apt or Suite..."
                control={control}
                errors={errors}
                rules={rules.address2()}
              />
            </Col>
          </FieldsGroupContent>
          <FieldsGroupContent>
            <TextField
              column
              name="raAddress.city"
              label="City"
              placeholder="City..."
              control={control}
              errors={errors}
              rules={rules.city()}
            />

            <SelectField
              column
              name="raAddress.state"
              label="State"
              placeholder="State..."
              options={getStatesOptions(true)}
              control={control}
              errors={errors}
              rules={rules.state()}
            />

            <TextField
              column
              name="raAddress.zip"
              label="ZIP"
              placeholder="12345..."
              maxLength={5}
              control={control}
              errors={errors}
              rules={rules.zip()}
            />
          </FieldsGroupContent>

          <FieldsGroupContent>
            <PhoneField
              column
              label="Phone"
              name="phone"
              placeholder="Phone..."
              control={control}
              errors={errors}
              rules={rules.phone()}
            />
          </FieldsGroupContent>
          <FieldsGroupContent>
            <TextField
              column
              autocomplete="email"
              label="Email"
              name="email"
              placeholder="Email..."
              control={control}
              errors={errors}
              rules={rules.email()}
            />
          </FieldsGroupContent>
        </FieldsGroup>
      </StyledForm>
    </>
  );
});

export default AmendmentChangeRegisterAgentForm;

AmendmentChangeRegisterAgentForm.propTypes = {
  handlers: PropTypes.shape({
    onSubmit: PropTypes.func.isRequired,
  }).isRequired,
};
