import PropTypes from "prop-types";
import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";

import {
  selectChangePaymentWithRerunSubscriptionLoading,
  selectPaymentMethods,
  selectPaymentMethodsLoading,
} from "_store/billing/selector";
import { createPaymentMethodWithRerunSubscription } from "_store/billing/slice";
import { selectActiveOrderId } from "_store/orders/selector";
import { getOrder } from "_store/orders/slice";
import { selectCustomerId } from "_store/user/selector";

import Button from "components/atomic/atoms/Button";
import { SpinnerWrapper } from "components/atomic/atoms/Spinner";
import EditPaymentMethodForm from "components/atomic/organisms/forms/EditPaymentMethodForm";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  /* min-height: 500px; */
  padding: 8px 12px;
  box-shadow: 0 0 3px lightgray inset;

  ${({ theme }) => css`
    background-color: ${theme.colors["cl-content-bg"]};
  `}
`;

const ButtonsGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 24px;
`;

const StyledButton = styled(Button)`
  min-width: 112px;
  width: 200px;
`;

const CardDeclinedErrorForm = ({ product, handleWasFormSubmitted }) => {
  const dispatch = useDispatch();

  const formRef = useRef(null);

  const handleConfirm = () => {
    formRef.current.submit();
  };

  const orderId = useSelector(selectActiveOrderId);
  const customerId = useSelector(selectCustomerId);
  const paymentMethods = useSelector(selectPaymentMethods);
  const paymentMethodsLoading = useSelector(selectPaymentMethodsLoading);
  const actionLoading = useSelector(selectChangePaymentWithRerunSubscriptionLoading);

  const primaryCardDetails = paymentMethods?.find((item) => item.primary) || null;

  function onSubmit(values) {
    handleWasFormSubmitted(true);

    const { cardReplacing, ...restCardDetails } = values;
    const cardDetails = { ...restCardDetails, paySourceId: cardReplacing ? primaryCardDetails?.paySourceId : null };

    dispatch(
      createPaymentMethodWithRerunSubscription({
        product,
        customerId,
        cardDetails,
        cb: () => {
          dispatch(getOrder({ customerId, orderId, redirectUrl: "../actions", refreshOrders: true }));
        },
      })
    );
  }

  return (
    <>
      <StyledContainer>
        <EditPaymentMethodForm ref={formRef} onSubmit={onSubmit} />

        <ButtonsGroup>
          <StyledButton primary type="button" disabled={paymentMethodsLoading} onClick={handleConfirm}>
            Submit
          </StyledButton>
        </ButtonsGroup>
      </StyledContainer>

      {actionLoading && <SpinnerWrapper />}
    </>
  );
};

CardDeclinedErrorForm.propTypes = {
  product: PropTypes.object,
  handleWasFormSubmitted: PropTypes.func,
};

export default CardDeclinedErrorForm;
